import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import useLockBodyScroll from "~/hooks/useLockBodyScroll";
import { cn } from "~/utilities/cn";

interface ModalTopProps {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children: React.ReactNode;
  closeOnOutsideClick?: boolean;
  className?: string;
  backdropClassName?: string;
}

const ModalTop: React.FC<ModalTopProps> = ({
  isOpen,
  onClose,
  onClick,
  children,
  closeOnOutsideClick = true,
  className = "",
  backdropClassName = "",
}) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(isOpen);

  // Lock body scroll when modal is open
  useLockBodyScroll(isVisible);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 400); // Match the duration of the animation
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsBrowser(true);

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsVisible(false);
        onClose();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        closeOnOutsideClick &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEsc);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleEsc);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleEsc);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, isOpen, closeOnOutsideClick]);

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      closeOnOutsideClick &&
      modalRef.current &&
      e.target === modalRef.current
    ) {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Escape" && onClick) {
      onClick();
    }
  };

  const modalContent = isVisible ? (
    <div
      className={cn(
        "fixed inset-0 left-0 right-0 top-0 z-50 flex items-center justify-center",
        backdropClassName
      )}
      role="presentation"
      onClick={handleOutsideClick}
      onKeyDown={handleKeyDown}
      ref={modalRef}
    >
      <div
        className={cn(
          "relative mx-auto mb-auto w-fit min-w-[300px] max-w-[80%]",
          isOpen ? "animate-animatetop" : "animate-animatetopExit",
          className
        )}
        role="dialog"
        aria-modal="true"
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return createPortal(modalContent, document.getElementById("modal-root")!);
  } else {
    return null;
  }
};

export default ModalTop;
