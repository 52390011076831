import { useEffect } from "react";

function useLockBodyScroll(isOpen: boolean) {
  useEffect(() => {
    // Ensure this runs only in the client-side rendering
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const originalOverflow = window.getComputedStyle(document.body).overflow;

      if (isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = originalOverflow;
      }

      // Cleanup function to restore the original overflow when the modal is closed
      return () => {
        document.body.style.overflow = originalOverflow;
      };
    }
  }, [isOpen]);
}

export default useLockBodyScroll;
